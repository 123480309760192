import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_ACTION,
} from "./loginTypes"
import { userLoginURL } from "../../urls"
import axios from "axios"
import { navigate } from "gatsby"

export const loginRequest = () => {
  // console.log("login request---->>>>>>>")
  return {
    type: USER_LOGIN_REQUEST,
  }
}

export const loginSuccess = (user) => {
  localStorage.setItem("user", JSON.stringify(user))
  return {
    type: USER_LOGIN_SUCCESS,
    payload: user,
  }
}
export const loginFailure = (errorMsg) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: errorMsg,
  }
}

export const logoutAction = () => {
  localStorage.setItem("user", JSON.stringify({}))
  return {
    type: USER_LOGOUT_ACTION,
  }
}

export const userLogin = (loginData) => {
  return (dispatch) => {
    dispatch(loginRequest())
    axios
      .post(userLoginURL, loginData)
      .then((response) => {
        if (response.data.statusCD === 200) {
          const user = response.data.responseData
          navigate("/")
          // console.log("login data from response", user)
          dispatch(loginSuccess(user))
        } else {
          dispatch(loginFailure(response.data.statusMSG))
          alert("Couldn't log you in! \n \n" + response.data.statusMSG)
        }
      })
      .catch((error, response) => {
        // console.log("login error", error, response)
        alert("Login failed", error.message)
        dispatch(loginFailure(error.message))
      })
  }
}
