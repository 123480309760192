import axios from "axios";

import {
  FETCH_WELLNESS_VIDEOS_FAILURE,
  FETCH_WELLNESS_VIDEOS_REQUEST,
  FETCH_WELLNESS_VIDEOS_SUCCESS,
  FETCH_VIDEO_CATEGORIES_SUCCESS,
} from "./WellnessVideosTypes";

import {
  fetchWellnessVideosURL,
  updateWellnessVideoURL,
  fetchVideoCategoriesURL,
} from "../../urls";

export const fetchVideoCategoriesSuccess = (videocategories) => {
  return {
    type: FETCH_VIDEO_CATEGORIES_SUCCESS,
    payload: videocategories,
  };
};

export const fetchVideoCategories = () => {
  return (dispatch) => {
    console.log("Fetching video categories");
    axios({
      method: "post",
      url: fetchVideoCategoriesURL,
      data: "10029",
      headers: {
        "content-type": "application/json",
      },
    })
      .then(async (response) => {
        console.log("Fetch vid cates Res", response.data.responseData);
        dispatch(fetchVideoCategoriesSuccess(response.data.responseData));
      })
      .catch((err) => {
        console.log("Fetch vid cates Err", err);
      });
  };
};

export const fetchWellnessVideosRequest = () => {
  return {
    type: FETCH_WELLNESS_VIDEOS_REQUEST,
  };
};

export const fetchWellnessVideosSuccess = (posts) => {
  return {
    type: FETCH_WELLNESS_VIDEOS_SUCCESS,
    payload: posts,
  };
};

export const fetchWellnessVideosFailure = (error) => {
  return {
    type: FETCH_WELLNESS_VIDEOS_FAILURE,
    payload: error,
  };
};

export const fetchMoreWellnessVideos = (data, concatPosts) => {
  return (dispatch) => {
    // data.pageNumber === 1 && dispatch(fetchWellnessVideosRequest());
    // fetch all the posts
    // try {
    console.log("Wellness Videos action");
    axios
      // ({
      //   method: "post",
      //   url: fetchWellnessVideosURL,
      //   data: data,
      //   headers: {
      //     "content-type": "application/json",
      //   },
      // })
      .post(fetchWellnessVideosURL, data)
      .then((res) => {
        var fetchPostsData = res.data.responseData;
        if (fetchPostsData === null || fetchPostsData.length === 0)
          return alert("No more posts to review in this category");

        // fetchPostsData = [];
        // data.pageNumber === 1 &&
        //   dispatch(fetchWellnessVideosSuccess(fetchPostsData));
        if (fetchPostsData === []) {
          return null;
        }
        return concatPosts(fetchPostsData);
        // console.log(fetchPostsData);
        //   if (fetchPostsData) {
        //     input.handleSuccessfullFeedPosts(fetchPostsData);
        //     input.setCurrentPage(currentPage + 1);
        //   }
        //   else if (input.currentPage === 0 && fetchPostsData === null) {
        //     console.log(
        //       input.currentPage === 0 && fetchPostsData === null
        //     );
        //     input.setNoFeed(true);
        //   } else {
        //     input.setIsTheEnd(true);
        //   }
        //   return input.setRefreshing(false);
      })
      .catch((err) => {
        console.log("Fetch Videos Err", err);
        dispatch(fetchWellnessVideosFailure(JSON.stringify(err)));
        //   return input.setRefreshing(false);
      });
    // } catch (error) {
    //   // console.error(error);
    //   // dispatch(fetchWellnessVideosFailure(error));
    //   //   return input.setRefreshing(false);
    // }
  };
};

export const fetchWellnessVideos = (data) => {
  return (dispatch) => {
    dispatch(fetchWellnessVideosRequest());
    // fetch all the posts
    // try {
    console.log("Wellness Videos action");
    axios
      // ({
      //   method: "post",
      //   url: fetchWellnessVideosURL,
      //   data: data,
      //   headers: {
      //     "content-type": "application/json",
      //   },
      // })
      .post(fetchWellnessVideosURL, data)
      .then((res) => {
        var fetchPostsData = res.data.responseData;
        if (fetchPostsData === null || fetchPostsData.length === 0)
          fetchPostsData = [];

        dispatch(fetchWellnessVideosSuccess(fetchPostsData));

        // console.log(fetchPostsData);
        //   if (fetchPostsData) {
        //     input.handleSuccessfullFeedPosts(fetchPostsData);
        //     input.setCurrentPage(currentPage + 1);
        //   }
        //   else if (input.currentPage === 0 && fetchPostsData === null) {
        //     console.log(
        //       input.currentPage === 0 && fetchPostsData === null
        //     );
        //     input.setNoFeed(true);
        //   } else {
        //     input.setIsTheEnd(true);
        //   }
        //   return input.setRefreshing(false);
      })
      .catch((err) => {
        console.log("Fetch Videos Err", err);
        dispatch(fetchWellnessVideosFailure(JSON.stringify(err)));
        //   return input.setRefreshing(false);
      });
    // } catch (error) {
    //   // console.error(error);
    //   // dispatch(fetchWellnessVideosFailure(error));
    //   //   return input.setRefreshing(false);
    // }
  };
};

export const updateWellnessVideo = (data) => {
  // fetch all the posts
  delete data.url;
  return (dispatch) => {
    try {
      axios
        .post(updateWellnessVideoURL, data)
        .then((res) => {
          // const fetchPostsData = await res.data.responseData;
          console.log(res.data.statusMSG);
          return alert(res.data.statusMSG);
          //   if (fetchPostsData) {
          //     input.handleSuccessfullFeedPosts(fetchPostsData);
          //     input.setCurrentPage(currentPage + 1);
          //   }
          //   else if (input.currentPage === 0 && fetchPostsData === null) {
          //     console.log(
          //       input.currentPage === 0 && fetchPostsData === null
          //     );
          //     input.setNoFeed(true);
          //   } else {
          //     input.setIsTheEnd(true);
          //   }
          //   return input.setRefreshing(false);
        })
        .catch((err) => {
          console.log("Fetch Videos Err", err);
          //   return input.setRefreshing(false);
        });
    } catch (e) {
      console.error(e);
      //   return input.setRefreshing(false);
    }
  };
};
