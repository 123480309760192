import axios from "axios"
import {
  FETCH_COUNSELLOR_REQUEST,
  FETCH_COUNSELLOR_SUCCESS,
  FETCH_COUNSELLOR_FAILURE,
  FETCH_COUNSELLOR_TIME_TRACK,
  FETCH_COUNSELLOR_STATUS,
  FETCH_COUNSELLOR_VERIFY,
} from "./counsellorTypes"
import {
  getListeners,
  fetchListenerTimeTracker,
  fetchListenerStatus,
  doctoriteId,
} from "../../urls"
export const fetchCounsellorRequest = () => {
  return {
    type: FETCH_COUNSELLOR_REQUEST,
  }
}

export const fetchCounsellorSuccess = (counsellors) => {
  return {
    type: FETCH_COUNSELLOR_SUCCESS,
    payload: counsellors,
  }
}

export const fetchCounsellorFailure = (error) => {
  return {
    type: FETCH_COUNSELLOR_FAILURE,
    payload: error,
  }
}

export const fetchCounsellorTimeTracker = (timeTracking) => {
  return {
    type: FETCH_COUNSELLOR_TIME_TRACK,
    payload: timeTracking,
  }
}

export const fetchCounsellorStatus = (status) => {
  return {
    type: FETCH_COUNSELLOR_STATUS,
    payload: status,
  }
}

export const fetchCounsellorVerify = (verify) => {
  return {
    type: FETCH_COUNSELLOR_VERIFY,
    payload: verify,
  }
}

export const fetchCounsellors = (user) => {
  return (dispatch) => {
    dispatch(fetchCounsellorRequest())
    let url = getListeners;
    if (doctoriteId !== user.clinicId && user.hasOwnProperty("organizationId")) {
      url = getListeners + `?organizationId=${user.organizationId}`
    }
    axios
      .get(url)
      .then((response) => {
        dispatch(fetchCounsellorSuccess(response?.data?.responseData))
      })
      .catch((error) => {
        dispatch(fetchCounsellorFailure(error.message))
      })
  }
}

export const fetchCounsellorTimeTrack = (listenerID, startDate, endDate) => {
  console.log(listenerID, startDate, endDate, "listenerID, startDate, endDate111")
  console.log(
    fetchListenerTimeTracker +
    `${listenerID}/timeTracker?startDate=${startDate}&endDate=${endDate}`,
    "99090090"
  )
  return (dispatch) => {
    axios
      .get(
        fetchListenerTimeTracker +
        `/${listenerID}/timeTracker?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        dispatch(fetchCounsellorTimeTracker(response?.data?.responseData))
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }
}

export const fetchCounsellorStatusApi = (listenerID, status) => {
  return (dispatch) => {
    axios
      .put(fetchListenerStatus, {
        id: listenerID,
        status: status === true ? 1 : 0,
      })
      .then((response) => {
        dispatch(fetchCounsellorStatus(response?.data?.responseData))
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }
}

export const fetchCounsellorVerifyApi = (verified, listenerID) => {
  return (dispatch) => {
    axios
      .put(fetchListenerTimeTracker + `/${listenerID}/verification`, {
        verified: verified,
      })
      .then((response) => {
        dispatch(fetchCounsellorVerify(response?.data?.responseData))
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }
}
