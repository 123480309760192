import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { Footer } from "./Footer"
import { NavBar } from "./NavBar"
import { Menu } from "./Menu"
import { DashboardLayout, GlobalStyle } from "../style/globalStyles"
export const Layout = ({ children }) => {
  const loginData = useSelector((state) => state.login.user.portalUserId)
  // const user = JSON.parse(localStorage.getItem("user"))
  //   ? JSON.parse(localStorage.getItem("user")).portalUserId
  //   : null
  const loginBool = Boolean(loginData)

  useEffect(() => {
    if (!loginBool) {
      navigate("/login")
    }
    // console.log("user from layout", loginBool)
  }, [loginBool])

  return (
    <DashboardLayout>
      <GlobalStyle />
      {/* <NavBar /> */}
      {loginBool ? <Menu /> : ""}
      <main>{children}</main>
      {/* <Footer /> */}
    </DashboardLayout>
  )
}
