import axios from "axios"
import {
  FETCH_DOCTORS_REQUEST,
  FETCH_DOCTORS_SUCCESS,
  FETCH_DOCTORS_FAILURE,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAILURE,
} from "./doctorTypes"
import {
  fetchDoctorsURL,
  insertOrUpdateDoctorURL,
  approveOrDisapproveDoctor,
  enableDoctor,
  disableDoctor,
  doctoriteId,
} from "../../urls"
export const fetchDoctorsRequest = () => {
  return {
    type: FETCH_DOCTORS_REQUEST,
  }
}

export const fetchDoctorsSuccess = (doctors) => {
  return {
    type: FETCH_DOCTORS_SUCCESS,
    payload: doctors,
  }
}

export const fetchDoctorsFailure = (error) => {
  return {
    type: FETCH_DOCTORS_FAILURE,
    payload: error,
  }
}

export const fetchDoctors = (user) => {
  var dataSource =
    doctoriteId === user.clinicId
      ? { source: "PORTAL" }
      : { source: "PORTAL", clinicId: user.clinicId }
  if (user.hasOwnProperty("organizationId")) {
    dataSource.organizationId = user.organizationId;
  }
  // console.log(user, dataSource)
  return (dispatch) => {
    dispatch(fetchDoctorsRequest())
    axios
      .post(fetchDoctorsURL, dataSource)
      .then((response) => {
        // console.log(response)
        var doctors = response.data.responseData || []
        doctors =
          doctoriteId === user.clinicId
            ? doctors
            : doctors
              .filter((item) => item.clinicId !== undefined)
              .filter((item) => item.clinicId === user.clinicId)
        // .filter((item) => item.clinicId !== undefined)
        // .filter((item) => item.clinicId === user.clinicId)
        localStorage.setItem("doctors", JSON.stringify(doctors))
        dispatch(fetchDoctorsSuccess(doctors))
      })
      .catch((error) => {
        // console.log(error)
        dispatch(fetchDoctorsFailure(error.message))
      })
  }
}

// Doctor profile update
export const updateDoctorSuccess = (doctorData) => {
  return {
    type: UPDATE_DOCTOR_SUCCESS,
    payload: doctorData,
  }
}
export const updateDoctorFailure = (error) => {
  return {
    type: UPDATE_DOCTOR_FAILURE,
    payload: error,
  }
}

export const updateDoctor = (doctorData) => {
  return (dispatch) => {
    // var isActiveURL = doctorData.isActive ? enableDoctor : disableDoctor
    var Headers = { headers: { "Content-Type": "application/json" } }
    let data = JSON.stringify({
      doctorId: doctorData.doctorId,
      isVerified: doctorData.isVerified,
    })
    // console.log("updateDoctor data", data)
    axios
      .post(approveOrDisapproveDoctor, data, Headers)
      .then((response) => {
        // console.log(response)
        alert(response.data.statusMSG)
        dispatch(updateDoctorSuccess(response.data.responseData))
      })
      .catch((error) => {
        // console.log(response)
        alert("Error changing status \n" + error.message)
        dispatch(updateDoctorFailure(error.message))
      })
  }
}
