import axios from "axios"
import {
  FETCH_PATIENTS_REQUEST,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_FAILURE,
  FETCH_SECONDARY_PATIENTS,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE,
} from "./patientTypes"
import {
  fetchPatientsURL,
  fetchSecondaryPatientsURL,
  insertOrUpdatePatientURL,
  doctoriteId,
} from "../../urls"
export const fetchPatientsRequest = () => {
  return {
    type: FETCH_PATIENTS_REQUEST,
  }
}

export const fetchPatientsSuccess = (patients) => {
  return {
    type: FETCH_PATIENTS_SUCCESS,
    payload: patients,
  }
}

export const fetchPatientsFailure = (error) => {
  return {
    type: FETCH_PATIENTS_FAILURE,
    payload: error,
  }
}

export const fetchPatients = (user) => {
  var dataSource =
    doctoriteId === user.clinicId
      ? { source: "PORTAL" }
      : { source: "PORTAL", clinicId: user.clinicId };
  if (user.hasOwnProperty("organizationId")) {
    dataSource.organizationId = user.organizationId;
  }
  return (dispatch) => {
    dispatch(fetchPatientsRequest())
    axios
      .post(fetchPatientsURL, dataSource)
      .then((response) => {
        console.log("response", response);
        var patients = response.data.responseData || []
        patients =
          doctoriteId === user.clinicId
            ? patients
            : patients
              .filter((item) => item.clinicId !== undefined || item.organizationId !== undefined)
              .filter((item) => item.clinicId === user.clinicId || item.organizationId === user.clinicId)
        dispatch(fetchPatientsSuccess(patients))
      })
      .catch((error) => {
        dispatch(fetchPatientsFailure(error.message))
      })
  }
}

export const fetchSecondaryPatientsSuccess = (secPatients) => {
  return {
    type: FETCH_SECONDARY_PATIENTS,
    payload: secPatients,
  }
}

export const fetchSecondaryPatients = (inputData, user) => {
  inputData["clinicId"] = user.clinicId
  var secondaryPatients
  return (dispatch) => {
    axios
      .post(fetchSecondaryPatientsURL, inputData)
      .then((response) => {
        secondaryPatients = response.data.responseData
        // console.log(secondaryPatients)
        // localStorage.setItem("patients", JSON.stringify(patients))
        dispatch(fetchSecondaryPatientsSuccess(secondaryPatients))
      })
      .catch((error) => {
        dispatch(fetchPatientsFailure(error.message))
      })
  }
}

export const updatePatientSuccess = (resData) => {
  return {
    type: UPDATE_PATIENT_SUCCESS,
    payload: resData,
  }
}
export const updatePatientFailure = (error) => {
  return {
    type: UPDATE_PATIENT_FAILURE,
    payload: error,
  }
}

export const updatePatient = (patientData) => {
  return (dispatch) => {
    // console.log("update appointment data", patientData, flag)
    axios
      .post(insertOrUpdatePatientURL, patientData)
      .then((response) => {
        // console.log(response)
        dispatch(updatePatientSuccess(response.data.responseData))
        // dispatch(fetchAppointmentsRequest())
        alert(response.data.statusMSG)
      })
      .catch((error) => {
        alert("An error occured \n" + error.message)
        dispatch(updatePatientFailure(error.message))
      })
  }
}
