import axios from "axios"
import {
    FETCH_APPOINTMENTS_COUNT,
    FETCH_APPOINTMENTS_COUNT_SUCCESS,
    FETCH_APPOINTMENTS_COUNT_FAILURE,
    FETCH_REQUEST_COUNT,
    FETCH_REQUEST_COUNT_SUCCESS,
    FETCH_REQUEST_COUNT_FAILURE,
    FETCH_VIDEO_VIEW_COUNT,
    FETCH_VIDEO_VIEW_COUNT_SUCCESS,
    FETCH_VIDEO_VIEW_COUNT_FAILURE,
    FETCH_VIDEO_VIEW_CATEGORY_COUNT,
    FETCH_LISTENER_HISTORY,
    FETCH_MOOD_HISTORY,
} from "./statsTypes"
import {
    getAppointmentsCountStatsURL,
    getRequestCountStatsURL,
    getVideoViewStatsURL,
    fetchListenerHistoryURL,
    fetchMoodHistoryURL,
} from "../../urls"

export const fetchAptCount = () => {
    return {
        type: FETCH_APPOINTMENTS_COUNT,
    }
}

export const fetchAptCountSuccess = (data) => {
    return {
        type: FETCH_APPOINTMENTS_COUNT_SUCCESS,
        payload: data,
    }
}

export const fetchAptCountFailure = (error) => {
    return {
        type: FETCH_APPOINTMENTS_COUNT_FAILURE,
        payload: error,
    }
}

export const fetchReqCount = () => {
    return {
        type: FETCH_REQUEST_COUNT,
    }
}

export const fetchReqCountSuccess = (data) => {
    return {
        type: FETCH_REQUEST_COUNT_SUCCESS,
        payload: data,
    }
}

export const fetchReqCountFailure = (error) => {
    return {
        type: FETCH_REQUEST_COUNT_FAILURE,
        payload: error,
    }
}

export const fetchVidViewCount = () => {
    return {
        type: FETCH_VIDEO_VIEW_COUNT,
    }
}

export const fetchVidViewCountSuccess = (data) => {
    return {
        type: FETCH_VIDEO_VIEW_COUNT_SUCCESS,
        payload: data,
    }
}

export const fetchVidViewCountFailure = (error) => {
    return {
        type: FETCH_VIDEO_VIEW_COUNT_FAILURE,
        payload: error,
    }
}

export const fetchVidViewCategoryCount = (data) => {
    return {
        type: FETCH_VIDEO_VIEW_CATEGORY_COUNT,
        payload: data,
    }
}

export const fetchListenerHistory = (data) => {
    return {
        type: FETCH_LISTENER_HISTORY,
        payload: data,
    }
}

export const fetchMoodHistory = (data) => {
    return {
        type: FETCH_MOOD_HISTORY,
        payload: data,
    }
}

export const fetchAptStat = (user, patientId) => {
    return (dispatch) => {
        dispatch(fetchAptCount())
        let url;
        if (patientId == undefined) {
            if (!user.hasOwnProperty("organizationId")) {
                url = getAppointmentsCountStatsURL;
            }
            else {
                url = getAppointmentsCountStatsURL + `?organizationId=${user.organizationId}`
            }
        }
        else {
            url = getAppointmentsCountStatsURL + `?patientId=${patientId}`
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                console.log("response", response);
                var aptCountData = response.data.responseData || {}
                dispatch(fetchAptCountSuccess(aptCountData))
            })
            .catch((error) => {
                dispatch(fetchAptCountFailure(error.message))
            })
    }
}

export const fetchReqStat = (user, patientId) => {
    return (dispatch) => {
        dispatch(fetchReqCount())
        let url;
        if (patientId == undefined) {
            if (!user.hasOwnProperty("organizationId")) {
                url = getRequestCountStatsURL;
            }
            else {
                url = getRequestCountStatsURL + `?organizationId=${user.organizationId}`
            }
        }
        else {
            url = getRequestCountStatsURL + `?patientId=${patientId}`
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                console.log("response", response)
                var reqCountData = response.data.responseData || {}
                dispatch(fetchReqCountSuccess(reqCountData))
            })
            .catch((error) => {
                dispatch(fetchReqCountFailure(error.message))
            })
    }
}

export const fetchVidViewStat = (user, patientId) => {
    return (dispatch) => {
        dispatch(fetchVidViewCount())
        let url;
        if (patientId == undefined) {
            if (!user.hasOwnProperty("organizationId")) {
                url = getVideoViewStatsURL;
            }
            else {
                url = getVideoViewStatsURL + `?organizationId=${user.organizationId}`
            }
        }
        else {
            url = getVideoViewStatsURL + `?patientId=${patientId}`
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                console.log("response", response)
                var vidViewCountData = response.data.responseData || {}
                dispatch(fetchVidViewCountSuccess(vidViewCountData))
            })
            .catch((error) => {
                dispatch(fetchVidViewCountFailure(error.message))
            })
    }
}

export const fetchVidViewCategoryStat = (user, category, patientId) => {
    return (dispatch) => {
        let url;
        if (patientId == undefined) {
            if (!user.hasOwnProperty("organizationId")) {
                url = getVideoViewStatsURL + `?category=${category.lookupId}`;
            }
            else {
                url = getVideoViewStatsURL + `?organizationId=${user.organizationId}&category=${category.lookupId}`
            }
        }
        else {
            url = getVideoViewStatsURL + `?patientId=${patientId}&category=${category.lookupId}`
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                console.log("response", response)
                var vidViewCategoryCountData = response.data.responseData || {}
                dispatch(fetchVidViewCategoryCount(vidViewCategoryCountData))
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const fetchListenerHistoryStat = (user, patientId) => {
    return (dispatch) => {
        let url;
        if (patientId == undefined) {
            if (!user.hasOwnProperty("organizationId")) {
                url = fetchListenerHistoryURL;
            }
            else {
                url = fetchListenerHistoryURL + `?organizationId=${user.organizationId}`
            }
        }
        else {
            url = fetchListenerHistoryURL + `?patientId=${patientId}`
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                console.log("response", response)
                var listenerHistory = response.data.responseData || {}
                dispatch(fetchListenerHistory(listenerHistory))
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const fetchMoodHistoryStat = (user, patientId) => {
    return (dispatch) => {
        let url;
        if (patientId == undefined) {
            if (!user.hasOwnProperty("organizationId")) {
                url = fetchMoodHistoryURL;
            }
            else {
                url = fetchMoodHistoryURL + `?organizationId=${user.organizationId}`
            }
        }
        else {
            url = fetchMoodHistoryURL + `?patientId=${patientId}`
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                console.log("response", response)
                var moodHistory = response.data.responseData || {}
                dispatch(fetchMoodHistory(moodHistory))
            })
            .catch((error) => {
                console.log(error);
            })
    }
}