import styled from "styled-components"

export const MenuSection = styled.section`
  grid-area: menu;
  width: 12rem;
  padding: 0.7rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1a202c;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  img {
    height: 2rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`

export const MenuLinks = styled.div`
  margin: 1rem 0;
  display: flex;
  color: white;

  flex-direction: column;
  justify-content: space-between;
  text-transform: capitalize;
  /* border: 0.1rem transparent solid; */

  a {
    margin-bottom: 1rem;
    color: "#fff";
  }
`
export const MenuItem = styled.p`
  padding: 0.5rem 0;
  padding-left: 1rem;
  font-size: 0.8rem;
  font-weight: normal;
  letter-spacing: 1px;
  border-radius: 3px;
  font-family: "Roboto", "Helvetica", "Sans-serif";
  border: 0.1rem transparent solid;
  :hover {
    /* background-color: #7579e7; */
    border-right: 0.5rem white solid;
    border-left: 0.5rem white solid;
    /* box-shadow: 0 1px 0.5rem rgba(0, 20, 40, 0.1); */
  }
`
