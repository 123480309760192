import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FooterSection, DownloadApp, Copyright } from "../style/footer"
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"
import twitter from "../images/twitter.png"
export const Footer = () => {
  const DoctoriteLogo = useStaticQuery(graphql`
    query {
      doctoritelogo: file(relativePath: { eq: "doctoritelogo.png" }) {
        publicURL
      }
      googlestore: file(relativePath: { eq: "googlestore.png" }) {
        publicURL
      }
      appstore: file(relativePath: { eq: "appstore.png" }) {
        publicURL
      }
    }
  `)

  return (
    <React.Fragment>
      <FooterSection>
        <>
          <div>
            <Link to="/">
              <img
                src={DoctoriteLogo.doctoritelogo.publicURL}
                alt="Logo of Doctorite"
              />{" "}
            </Link>
            <aside>
              <a href="https://www.facebook.com/">
                <img src={facebook} alt="facebook"></img>
              </a>
              <a href="https://www.instagram.com/">
                <img src={instagram} alt="instagram"></img>
              </a>
              <a href="https://www.twitter.com/">
                <img src={twitter} alt="twitter"></img>
              </a>
            </aside>
          </div>
          <div>
            <span>
              <Link to="/">Doctorite</Link>
            </span>
            {/* <Link to="/about">About</Link> */}
            {/* <Link to="/doctorsignup">List your practice</Link> */}
            <Link to="/contact">Contact us</Link>
            <Link to="/tandc">Terms & Conditions</Link>
            <Link to="/faqs">FAQs</Link>
            {/* <Link to="/feedback">Feedback</Link> */}
          </div>
          <DownloadApp>
            <p>Get the Doctorite app</p>
            <div
              style={{
                marginTop: "3rem",
                display: "flex",
                flexWrap: "wrap",
                padding: "0",
                justifyContent: "space-around",
              }}
            >
              <Link to="/">
                <img
                  src={DoctoriteLogo.appstore.publicURL}
                  alt="Download Doctorite app from appstore"
                />
              </Link>
              <Link to="/">
                <img
                  src={DoctoriteLogo.googlestore.publicURL}
                  alt="Download Doctorite app from playstore"
                />
              </Link>
            </div>
          </DownloadApp>
        </>
      </FooterSection>
      <Copyright>
        {" "}
        <p>
          Copyrights © {new Date().getFullYear()} Twilight IT Professionals Pvt.
          Ltd.
        </p>{" "}
      </Copyright>
    </React.Fragment>
  )
}
