import axios from "axios"

import {
  FETCH_APPOINTMENTS_REQUEST,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_APPOINTMENTS_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
} from "./appointmentTypes"

import {
  fetchAppointmentsURL,
  updateAppointmentStatusURL,
  doctoriteId,
} from "../../urls"

// Fetch appointments
export const fetchAppointmentsRequest = () => {
  return {
    type: FETCH_APPOINTMENTS_REQUEST,
  }
}

export const fetchAppointmentsSuccess = (appointments) => {
  return {
    type: FETCH_APPOINTMENTS_SUCCESS,
    payload: appointments,
  }
}

export const fetchAppointmentsFailure = (error) => {
  return {
    type: FETCH_APPOINTMENTS_FAILURE,
    payload: error,
  }
}

export const fetchAppointments = (user) => {
  var dataSource =
    doctoriteId === user.clinicId
      ? { source: "PORTAL" }
      : { source: "PORTAL", clinicId: user.clinicId };
  if (user.hasOwnProperty("organizationId")) {
    dataSource.organizationId = user.organizationId;
  }
  return (dispatch) => {
    dispatch(fetchAppointmentsRequest())
    axios
      .post(fetchAppointmentsURL, dataSource)
      .then((response) => {
        var appointments = response.data.responseData || []
        // appointments =
        //   doctoriteId === user.clinicId
        //     ? appointments
        //     : appointments
        //         .filter((item) => item.clinicId !== undefined)
        //         .filter((item) => item.clinicId === user.clinicId)
        appointments.length !== 0 &&
          appointments.sort(
            (appointmentA, appointmentB) =>
              new Date(appointmentB.appointmentDate) -
              new Date(appointmentA.appointmentDate)
          )
        dispatch(fetchAppointmentsSuccess(appointments))
      })
      .catch((error) => {
        dispatch(fetchAppointmentsFailure(error.message))
      })
  }
}

// Appointment status update

export const updateAppointmentSuccess = (resData) => {
  return {
    type: UPDATE_APPOINTMENT_SUCCESS,
    payload: resData,
  }
}
export const updateAppointmentFailure = (error) => {
  return {
    type: UPDATE_APPOINTMENT_FAILURE,
    payload: error,
  }
}

export const updateAppointment = (appointmentData, flag) => {
  const appointmentUpdateData = {
    orderSeqId: appointmentData.orderSeqId,
    orderStatus: appointmentData.orderStatus,
  }
  return (dispatch) => {
    // console.log("update appointment data", appointmentData, flag)
    if (flag === "statusUpdate") {
      axios
        .post(updateAppointmentStatusURL, appointmentUpdateData)
        .then((response) => {
          // console.log(response)
          dispatch(updateAppointmentSuccess(appointmentData))
          // dispatch(fetchAppointmentsRequest())
          alert(response.data.statusMSG)
        })
        .catch((error) => {
          alert("An error occured", error)
          dispatch(updateAppointmentFailure(error.message))
        })
    }
  }
}
