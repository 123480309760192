// fetchLookupByCategory

// Input - 10025

// fetchDoctorById, fetchHealthCareProfessionalById

// Input -
// {
//       "doctorId":  1
// }

// Or

// {
//       "professionalId":  1
// }

// insertOrUpdateDoctor

// {"doctorId":1,"clinicId":21,"firstName":"Syed","lastName":"Sharaan","age":24,"addressLine1":"Gachibowli, Hyderabad, Telangana, India","addressLine2":"102/D","city":"Hyderabad","state":"Telangana","pinCode":123456,"country":"India","mobileNo":"9573606036","email":"syed@zyppys.com","imageUrl":"https://doctorinfo.s3.amazonaws.com/syed%40zyppys.com%2FprofileImage.jpg?1600694359300","doctorSignature":"https://doctorinfo.s3.amazonaws.com/syed%40zyppys.com%2FdoctorSignature.jpg?1600694080352","specialityId":100010003,"experience":5,"about":"I am a dermatologist","imcRegistrationNo":"45204798","university":"","isActive":true,"gender":100030002,"degree":"100050001,100050007,100050008","countryId":1,"verificationContact":"","consultationFees":100,"isVerified":true,"language":"0"}

// insertOrapproveOrDissapproveHealthCareProfessional

// {"professionalId":1,"clinicId":21,"firstName":"Syed","lastName":"Sharaan","age":24,"addressLine1":"Gachibowli, Hyderabad, Telangana, India","addressLine2":"102/D","city":"Hyderabad","state":"Telangana","pinCode":123456,"country":"India","mobileNo":"9573606036","email":"syed@zyppys.com","imageUrl":"https://doctorinfo.s3.amazonaws.com/syed%40zyppys.com%2FprofileImage.jpg?1600694359300","specialityId":100010003,"experience":5,"about":"I am a dermatologist","imcRegistrationNo":"45204798","university":"","isActive":true,"gender":100030002,"degree":"100050001,100050007,100050008","countryId":1,"verificationContact":"","consultationFees":100,"isVerified":true,"language":"0","registrationCertificate": "","educationalCertificate": ""}

// fetchHealthCareProfessionals

// {
//     "source": "APP",
//     "countryId": 1,
//     "professionType" : 100240005,
//     "clinicId": 1
// }

import axios from "axios"
import {
  FETCH_HEALTH_CARE_PROFESSIONALS_REQUEST,
  FETCH_HEALTH_CARE_PROFESSIONALS_SUCCESS,
  FETCH_HEALTH_CARE_PROFESSIONALS_FAILURE,
  UPDATE_HEALTH_CARE_PROFESSIONAL_SUCCESS,
  UPDATE_HEALTH_CARE_PROFESSIONAL_FAILURE,
  FETCH_PROFESSION_TYPE_SUCCESS,
  FETCH_PROFESSION_TYPE_FAILURE,
} from "./healthCareProfessionalTypes"
import {
  fetchHealthCareProfessionalsURL,
  approveOrDisapproveHealthCareProfessionalURL,
  fetchLookupByCategoryURL,
  enableHealthCareProfessionalURL,
  disableHealthCareProfessionalURL,
  doctoriteId,
} from "../../urls"

export const fetchProfessionTypesSuccess = (professiontypes) => {
  return {
    type: FETCH_PROFESSION_TYPE_SUCCESS,
    payload: professiontypes,
  }
}
export const fetchProfessionTypesError = (error) => {
  return {
    type: FETCH_PROFESSION_TYPE_FAILURE,
    payload: error,
  }
}

export const fetchProfessionTypes = () => {
  var data = "10024"
  const headers = {
    "content-type": "application/json",
  }
  return (dispatch) => {
    axios({
      method: "post",
      url: fetchLookupByCategoryURL,
      data: "10024",
      headers: {
        "content-type": "application/json",
      },
    })
      //   .post(fetchLookupByCategoryURL, data, headers)
      .then((response) => {
        // console.log(response)
        const professionTypes = response.data.responseData
        dispatch(fetchProfessionTypesSuccess(professionTypes))
      })
      .catch((error) => {
        console.log(error)
        dispatch(fetchProfessionTypesError(error.message))
      })
  }
}

export const fetchHealthCareProfessionalsRequest = () => {
  return {
    type: FETCH_HEALTH_CARE_PROFESSIONALS_REQUEST,
  }
}

export const fetchHealthCareProfessionalsSuccess = (
  healthCareProfessionals
) => {
  return {
    type: FETCH_HEALTH_CARE_PROFESSIONALS_SUCCESS,
    payload: healthCareProfessionals,
  }
}

export const fetchHealthCareProfessionalsFailure = (error) => {
  return {
    type: FETCH_HEALTH_CARE_PROFESSIONALS_FAILURE,
    payload: error,
  }
}

export const fetchHealthCareProfessionals = (professionId, user) => {
  var dataSource =
    doctoriteId === user.clinicId
      ? {
        source: "PORTAL",
        professionType: professionId,
      }
      : {
        source: "PORTAL",
        // countryId: 1,
        clinicId: user.clinicId,
        professionType: professionId,
      }
  if (user.hasOwnProperty("organizationId")) {
    dataSource.organizationId = user.organizationId;
  }
  return (dispatch) => {
    dispatch(fetchHealthCareProfessionalsRequest())
    axios
      .post(fetchHealthCareProfessionalsURL, dataSource)
      .then((response) => {
        // console.log(response)
        var healthCareProfessionals = response.data.responseData || []

        // healthCareProfessionals =
        //   doctoriteId === user.clinicId
        //     ? healthCareProfessionals
        //     : healthCareProfessionals
        //       .filter((item) => item.clinicId !== undefined)
        //       .filter((item) => item.clinicId === user.clinicId)
        dispatch(fetchHealthCareProfessionalsSuccess(healthCareProfessionals))
      })
      .catch((err) => {
        console.log(err)
        dispatch(fetchHealthCareProfessionalsFailure(JSON.stringify(err)))
      })
  }
}

// healthCareProfessional profile update
export const approveOrDissapproveHealthCareProfessionalSuccess = (
  updatedHCP
) => {
  return {
    type: UPDATE_HEALTH_CARE_PROFESSIONAL_SUCCESS,
    payload: updatedHCP,
  }
}
export const approveOrDissapproveHealthCareProfessionalFailure = (error) => {
  return {
    type: UPDATE_HEALTH_CARE_PROFESSIONAL_FAILURE,
    payload: error,
  }
}

export const approveOrDissapproveHealthCareProfessional = (
  healthCareProfessionalData,
  verifiedChangeSuccess
) => {
  return (dispatch) => {
    // var isActiveURL = healthCareProfessionalData.isActive ? enablehealthCareProfessional : disablehealthCareProfessional
    var Headers = { headers: { "Content-Type": "application/json" } }
    let data = {
      professionalId: healthCareProfessionalData.professionalId,
      isVerified: healthCareProfessionalData.isVerified,
    }
    console.log(approveOrDisapproveHealthCareProfessionalURL, data)
    axios
      .post(approveOrDisapproveHealthCareProfessionalURL, data, Headers)
      .then((response) => {
        // console.log(response)

        if (response.data.statusCD === 200) {
          verifiedChangeSuccess(response.data.responseData)
          dispatch(
            approveOrDissapproveHealthCareProfessionalSuccess(
              response.data.responseData
            )
          )
          alert(response.data.statusMSG)
        }
        // dispatch(approveOrDissapproveHealthCareProfessionalSuccess(response.data.statusMSG))
      })
      .catch((error) => {
        alert("Error Occured, Update Failed!", error.message)
        // dispatch(approveOrDissapproveHealthCareProfessionalFailure(error.message))
      })
  }
}

export const enableOrDisableHealthCareProfessional = (
  healthCareProfessionalData,
  activeChangeSuccess
) => {
  return (dispatch) => {
    // var isActiveURL = healthCareProfessionalData.isActive ? enablehealthCareProfessional : disablehealthCareProfessional
    var Headers = { headers: { "Content-Type": "application/json" } }
    let data = {
      professionalId: healthCareProfessionalData.professionalId,
      // isActive: healthCareProfessionalData.isActive,
    }
    var urlToUse = healthCareProfessionalData.isActive
      ? enableHealthCareProfessionalURL
      : disableHealthCareProfessionalURL
    console.log(urlToUse, data)
    // console.log("approveOrDissapproveHealthCareProfessional data", data)
    axios
      .post(urlToUse, data, Headers)
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          console.log("enable/disable success")
          activeChangeSuccess(healthCareProfessionalData.isActive)
          dispatch(
            approveOrDissapproveHealthCareProfessionalSuccess(
              response.data.responseData
            )
          )
          alert(response.data.statusMSG)
        } // dispatch(approveOrDissapproveHealthCareProfessionalSuccess(response.data.statusMSG))
      })
      .catch((error) => {
        console.log("Error Occured, Update Failed!", error.message)
        alert("Error Occured, Update Failed!", error.message)
        // dispatch(approveOrDissapproveHealthCareProfessionalFailure(error.message))
      })
  }
}
