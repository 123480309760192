import axios from "axios"
import {
  FETCH_CLINICS_REQUEST,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_FAILURE,
  ADD_CLINIC_SUCCESS,
  ADD_CLINIC_FAILURE,
  INSERT_OR_UPDATE_CLINIC_SUCCESS,
} from "./clinicTypes"
import {
  insetOrUpdateClinicURL,
  fetchClinicsURL,
  doctoriteId,
} from "../../urls"
export const fetchClinicsRequest = () => {
  return {
    type: FETCH_CLINICS_REQUEST,
  }
}

export const fetchClinicsSuccess = (clinics) => {
  return {
    type: FETCH_CLINICS_SUCCESS,
    payload: clinics,
  }
}

export const fetchClinicsFailure = (error) => {
  return {
    type: FETCH_CLINICS_FAILURE,
    payload: error,
  }
}

export const fetchClinics = (user) => {
  var dataSource =
    doctoriteId === user.clinicId
      ? { source: "PORTAL" }
      : { source: "PORTAL", id: user.clinicId }
  return (dispatch) => {
    dispatch(fetchClinicsRequest())
    axios
      .post(fetchClinicsURL, dataSource)
      .then((response) => {
        const clinics = response.data.responseData || []
        // localStorage.setItem("clinics", JSON.stringify(clinics))
        // console.log(response)
        dispatch(fetchClinicsSuccess(clinics))
      })
      .catch((error) => {
        // alert("Error fetching clinics!", error.message)
        dispatch(fetchClinicsFailure(error.message))
      })
  }
}

// Add or Update Clinic
export const insertOrUpdateClinicSuccess = (clinic) => {
  return {
    type: INSERT_OR_UPDATE_CLINIC_SUCCESS,
    payload: clinic,
  }
}

export const insertOrUpdateClinic = (clinicData) => {
  return (dispatch) => {
    console.log("dispatch new org", clinicData)
    axios
      .post(insetOrUpdateClinicURL, clinicData)
      .then((response) => {
        // console.log(response)
        alert(response.data.statusMSG)
        // dispatch(newclinicsuccess(ClinicData.ClinicName))
      })

      .catch((error, response) => {
        alert("Error Updating Clinic, please try again. \n" + error.message)

        // console.log(response)
        // dispatch(newClinicFailure(error.message))
      })
  }
}
