import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { NavBarSection, LogoSection, NavLinks, Text } from "../style/navbar"
import { MenuSection, MenuLinks, MenuItem } from "../style/menu"
import { logoutAction } from "../state"
import { doctoriteId } from "../urls"
const ActiveStyle = {
  borderRadius: "3px",
  backgroundColor: "white",
  color: "#23374d",
  border: "none",
  borderRight: "0.5rem white solid",
  borderLeft: "0.5rem white solid",
  boxShadow: "0 1px 0.5rem rgba(0, 20, 40, 0.1)",
}
export const Menu = () => {
  const DoctoriteLogo = useStaticQuery(graphql`
    query {
      doctoritelogo: file(relativePath: { eq: "doctoritelogo.png" }) {
        publicURL
      }
    }
  `)
  const dispatch = useDispatch()
  const loginData = useSelector((state) => state.login.user)
  const loginBool = Boolean(loginData.portalUserId)
  // console.log("user from navbar", loginData)
  const isMaster = loginData.hasOwnProperty("isMaster") ? loginData.isMaster : false;

  const handleLogout = () => {
    dispatch(logoutAction())
    dispatch({ type: "USER_LOGGED_OUT" })
    localStorage.setItem("user", JSON.stringify({}))
    navigate("/login")
  }
  return (
    <MenuSection>
      <LogoSection>
        <Link style={{ display: "flex", alignItems: "center" }} to="/">
          <img
            src={DoctoriteLogo.doctoritelogo.publicURL}
            alt="Logo of Doctorite"
          />
        <Text>Doctorite</Text>
        </Link>
      </LogoSection>
      <MenuLinks>
        {(isMaster || !loginData.hasOwnProperty("organizationId")) && <Link
          activeStyle={ActiveStyle}
          partiallyActive={true}
          style={{ color: "white" }}
          to="/doctors"
        >
          <MenuItem>Doctors</MenuItem>
        </Link>}
        <Link
          activeStyle={ActiveStyle}
          partiallyActive={true}
          style={{ color: "white" }}
          to="/patients"
        >
          <MenuItem>{!loginData.hasOwnProperty("organizationId") ? "Patients" : "Clients"}</MenuItem>
        </Link>
        {(isMaster || doctoriteId === loginData?.clinicId) && <Link
          activeStyle={ActiveStyle}
          partiallyActive={true}
          style={{ color: "white" }}
          to="/counsellors"
        >
          <MenuItem>Counsellors</MenuItem>
        </Link>}
        <Link
          activeStyle={ActiveStyle}
          partiallyActive={true}
          style={{ color: "white" }}
          to="/appointments"
        >
          <MenuItem>Appointments</MenuItem>
        </Link>
        {(isMaster || !loginData.hasOwnProperty("organizationId")) && <Link
          activeStyle={ActiveStyle}
          partiallyActive={true}
          style={{ color: "white" }}
          to="/healthcareprofessionals"
        >
          <MenuItem>Health Care Profs.</MenuItem>
        </Link>}
        {(isMaster || !loginData.hasOwnProperty("organizationId")) && loginData.clinicId === doctoriteId && (
          <>
            <Link
              activeStyle={ActiveStyle}
              partiallyActive={true}
              style={{ color: "white" }}
              to="/organisations"
            >
              <MenuItem>Organisations</MenuItem>
            </Link>
            <Link
              activeStyle={ActiveStyle}
              partiallyActive={true}
              style={{ color: "white" }}
              to="/clinics"
            >
              <MenuItem>Clinics</MenuItem>
            </Link>
            <Link
              activeStyle={ActiveStyle}
              partiallyActive={true}
              style={{ color: "white" }}
              to="/wellnessvideos"
            >
              <MenuItem>Wellness Videos</MenuItem>
            </Link>
          </>
        )}
        {(isMaster || !loginData.hasOwnProperty("organizationId")) && (
          <>
            <Link
              activeStyle={ActiveStyle}
              partiallyActive={true}
              style={{ color: "white" }}
              to="/analytics"
            >
              <MenuItem>Anaytics</MenuItem>
            </Link>
            <Link
              activeStyle={ActiveStyle}
              partiallyActive={true}
              style={{ color: "white" }}
              to="/notifications"
            >
              <MenuItem>Notifications</MenuItem>
            </Link>
          </>
        )}
      </MenuLinks>

      <NavLinks>
        {loginBool === true ? (
          <>
            <h4
              style={{
                color: "white",
                margin: "0.5rem 1rem",
                textTransform: "capitalize",
                fontWeight: "normal",
              }}
            >
              {loginData.firstName} {loginData.lastName}
            </h4>
            <button onClick={handleLogout}>Logout</button>
            <p
              style={{
                fontSize: "0.7rem",
                marginTop: "1rem",
                textAlign: "center",
                color: "white",
              }}
            >
              v 0.8.16
            </p>
          </>
        ) : (
          " "
        )}
      </NavLinks>
    </MenuSection>
  )
}
