import axios from "axios"
import {
  FETCH_ORGANISATIONS_REQUEST,
  FETCH_ORGANISATIONS_SUCCESS,
  FETCH_ORGANISATIONS_FAILURE,
  ADD_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_FAILURE,
  ORGANISATION_TYPES,
} from "./organisationTypes"
import {
  fetchOrganisationsURL,
  addNewOrganisationURL,
  doctoriteId,
  fetchLookupByCategoryURL,
} from "../../urls"
export const fetchOrganisationsRequest = () => {
  return {
    type: FETCH_ORGANISATIONS_REQUEST,
  }
}

export const fetchOrganisationsSuccess = (organisations) => {
  return {
    type: FETCH_ORGANISATIONS_SUCCESS,
    payload: organisations,
  }
}

export const fetchOrganisationsFailure = (error) => {
  return {
    type: FETCH_ORGANISATIONS_FAILURE,
    payload: error,
  }
}

export const updateOrganisationTypes = (organisationTypes) => {
  return {
    type: ORGANISATION_TYPES,
    payload: organisationTypes,
  }
}

export const fetchOrganisations = (user) => {
  var dataSource =
    doctoriteId === user.clinicId
      ? { source: "PORTAL" }
      : { source: "PORTAL", clinicId: user.clinicId }
  return (dispatch) => {
    dispatch(fetchOrganisationsRequest())
    axios
      .post(fetchOrganisationsURL, dataSource)
      .then((response) => {
        const organisations = response.data.responseData || []
        // localStorage.setItem("organisations", JSON.stringify(organisations))
        // console.log(response)
        dispatch(fetchOrganisationsSuccess(organisations))
      })
      .catch((error) => {
        dispatch(fetchOrganisationsFailure(error.message))
      })
  }
}

// Add new organisation

export const newOrganisationSuccess = (orgName) => {
  return {
    type: ADD_ORGANISATION_SUCCESS,
    payload: `Organisation "${orgName}" added successfully`,
  }
}
export const newOrganisationFailure = (error) => {
  return {
    type: ADD_ORGANISATION_FAILURE,
    payload: error,
  }
}

export const addNewOrganisation = (organisationData) => {
  return (dispatch) => {
    // console.log("dispatch new org", organisationData)
    axios
      .post(addNewOrganisationURL, organisationData)
      .then((response) => {
        // console.log(response)
        dispatch(newOrganisationSuccess(organisationData.organisationName))
      })
      .catch((error, response) => {
        // console.log(response)
        dispatch(newOrganisationFailure(error.message))
      })
  }
}

export const fetchOrganisationTypes = () => {
  var data = "10026"
  const headers = {
    "content-type": "application/json",
  }
  return (dispatch) => {
    axios({
      method: "post",
      url: fetchLookupByCategoryURL,
      data: "10026",
      headers: {
        "content-type": "application/json",
      },
    })
      //   .post(fetchLookupByCategoryURL, data, headers)
      .then((response) => {
        // console.log(response)
        const organisationTypes = response.data.responseData
        dispatch(updateOrganisationTypes(organisationTypes))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}